import HeroSection from "../../components/HeroSection";
import LeadGen from "../../components/LeadGen";

export default function Home () {
    return (
        <>
          {/* <HeroSection /> */}
          <LeadGen />
        </>
    )
}