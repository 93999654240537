import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery, useTheme } from '@mui/material';

export default function SuccessModal({
    open,
    setOpen
}) {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '75%' : 450,
        bgcolor: 'white',
        border: 'none',
        boxShadow: 24,
        borderRadius: 4,
        p: 4,
      };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
            <center>
            <img src='https://oredomotor.com/images/success.svg' style={{width: 80, marginBottom: '2%'}}/>
            </center>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center'}}>
            Thank You!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 , textAlign: 'center'}}>
            We have successfully received your reuqest regarding US LLC Formation, our team will connect with you shortly.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}