import { Grid } from "@mui/material";
import LeadGenForm from "./LeadGenForm";

export default function LeadGen () {
    return (
        <>
        <Grid container spacing={0} style={{
            background: '#f6fff0',
            height: '100vh',
            backgroundImage: "url(/images/green-logo.png)",
            backgroundSize: 'contain',
            backgroundPosition: 'start'
        }}>
            <Grid item md={6} style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center'
            }}>
                {/* <img src="/images/testimonials.svg" style={{width: '40%' }}/> */}
            </Grid>
            <Grid item md={6} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LeadGenForm />
            </Grid>

        </Grid>
        </>
    )
}