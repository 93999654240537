import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Autocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { Country } from 'country-state-city';
import { useMediaQuery, useTheme } from '@mui/material';
import emailjs from 'emailjs-com';
import SuccessModal from './SuccessModel';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  borderRadius: 10,
  backgroundColor: 'white',
  width: '400px',
  overflowY: 'auto',
  maxHeight: '87%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const LeadGenForm = () => {

  const [open, setOpen] = useState(false)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
    requirement: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleCountryChange = (event, value) => {
    setFormData({ ...formData, country: value ? value.name : '' });
    if (value) {
      setErrors((prev) => ({ ...prev, country: '' }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^[0-9]{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
    if (!formData.country) newErrors.country = 'Country is required';
    if (!formData.requirement) newErrors.requirement = 'Your Requirement is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      emailjs.send(
        'service_7c0ltrr',
        'template_2h511np',
        formData,
        'm6-Zjo4I2uIKNv_mk'
      ).then((result) => {
        setOpen(true);
        setLoading(false)
      }, (error) => {
        console.error(error);
      });
    }
  };

  return (
<>
<Container style={{ width: isMobile ? '330px' : '400px', marginLeft: isMobile ? '4%' : '' }}>
      <Header style={{ margin: 0, padding: 0 }}>
        <img src='/images/logo.png' style={{ width: 180 }} alt="Logo" />
      </Header>
      <p style={{ marginTop: '-3%', marginBottom: '5%', padding: 0 }}>
        Connect with us, to form your US LLC seamlessly
      </p>
      <FormBox component="form" onSubmit={handleSubmit}>
        <TextField
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={Boolean(errors.firstName)}
          helperText={errors.firstName}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={Boolean(errors.lastName)}
          helperText={errors.lastName}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Work Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber}
          fullWidth
          variant="outlined"
        />
        <Autocomplete
          options={Country.getAllCountries()}
          getOptionLabel={(option) => option.name}
          onChange={handleCountryChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              name="country"
              error={Boolean(errors.country)}
              helperText={errors.country}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <TextField
          label="Your Requirement"
          name="requirement"
          value={formData.requirement}
          onChange={handleChange}
          error={Boolean(errors.requirement)}
          helperText={errors.requirement}
          fullWidth
          variant="outlined"
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{
            background: '#163300',
            boxShadow: 'none',
            borderRadius: 10,
            padding: '10px 0',
          }}
        >
         {loading ? 'Get in Touch...' : 'Get in Touch'}
        </Button>
      </FormBox>
    </Container>
    <SuccessModal open={open} setOpen={setOpen}/>
</>
  );
};

export default LeadGenForm;
